import { atom } from 'recoil';
import { GlobalStyle } from '../../types/Config';

export const sidebarExtendedState = atom<boolean>({
  key: 'sidebarExtendedState',
  default: true,
});

export const layoutExtendedState = atom<boolean>({
  key: 'layoutExtendedState',
  default: false,
});

export const themeState = atom<GlobalStyle | null>({
  key: 'themeState',
  default: null,
});

export const highlightedRowState = atom<string | null>({
  key: 'highlightedRowState',
  default: null,
});

export const currencyState = atom<string>({
  key: 'currencyState',
  default: '€',
});
